import { updateUserAttribute } from 'aws-amplify/auth';

export async function updateIsDealerAttribute(isDealerValue: string) {
  try {
    const output = await updateUserAttribute({
      userAttribute: {
        attributeKey: 'custom:dealer',
        value: isDealerValue,
      },
    });
    console.log('Attribute update successful:', output);
  } catch (error) {
    console.error('Error updating attribute:', error);
  }
}
